<template>
    <Modal
        title="證照資訊"
        v-model="isShowModal"
        :isFloat="$isMobile() ? false : true"
    >
        <template v-slot:modal-content>
            <div v-if="isError" class="dashboard-container error">
                <p class="subtitle" :class="{ loading: isLoading }">
                    {{ isLoading ? '重新讀取中...' : '資訊載入錯誤！' }}
                </p>
                <p class="date-column">
                    無法取得證照資訊<template
                        v-if="$hasPermission('certificate')"
                        >，請<span
                            @click="
                                getLicenseInfo(),
                                    this.$setGaEvent(
                                        `getDashboardLicenseAPI`,
                                        'error-reacquire'
                                    )
                            "
                            >按此重新取得</span
                        >。<br />
                        若還是無法取得資料，請洽詢飛鴿。
                    </template>
                </p>
            </div>

            <div
                v-for="(item, index) in licenseData"
                :key="index"
                class="dashboard-container"
            >
                <p class="subtitle">
                    <span class="gradient-dot-icon" />{{ item.title }}
                </p>
                <p
                    v-for="(data, dataIndex) in item.dates"
                    :key="dataIndex"
                    :class="{
                        preload: isLoading,
                        'line-break': data.lineBreak,
                        'last-item': dataIndex + 1 === item.dates.length
                    }"
                    class="date-column"
                >
                    {{ data.label }}：
                    <span class="text-line">{{
                        isLoading ? '' : data.date
                    }}</span>
                </p>
                <div v-if="item.key === 'dueDate'" class="note">
                    <span class="red">*</span>使用<span class="red">{{
                        applyLicense
                    }}</span
                    >證照報名，每年需重新驗證安全碼，請於重疊時間修習共同單元
                </div>
                <div class="link-container">
                    <a
                        v-for="(data, linkIndex) in item.links"
                        :key="linkIndex"
                        :href="data.link"
                        target="_blank"
                        class="link-button"
                        @click="
                            this.$setGaEvent(`${item.key}Url`, 'click-link')
                        "
                    >
                        <Button
                            buttonStyle="dashboard"
                            :buttonWord="data.label"
                    /></a>
                </div>
                <div v-if="item.key === 'dueDate'" class="note">
                    <span class="red">*</span
                    >修習課程及確認完訓紀錄，請至保發中心及磊山e學院查詢
                </div>
            </div>
        </template>
        <template v-slot:modal-bottom> </template>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import { getDashboardLicenseAPI } from '@/assets/javascripts/api.js'

export default {
    name: 'LicenseInfoModal',
    emits: ['update:modelValue'],
    components: {
        Modal,
        Button
    },
    props: {
        modelValue: {
            type: Boolean,
            default: function () {
                return false
            }
        }
    },
    methods: {
        getLicenseInfo: async function () {
            if (!this.$isLogin()) return
            try {
                if (!this.$hasPermission('certificate'))
                    throw new Error('No Permission')
                this.isLoading = true
                const res = await getDashboardLicenseAPI()
                this.$store.commit('set', {
                    dashboardLicense: res.data
                })
                this.isError = false
            } catch {
                this.$store.commit('set', {
                    dashboardLicense: null
                })
                this.isError = true
            } finally {
                this.isLoading = false
            }
        }
    },
    computed: {
        isShowModal: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        },
        licenseData: function () {
            let licenseData = this.$store.state.dashboardLicense
            const getDataState = function (date) {
                if (date === null) {
                    return '無'
                } else if (!date) {
                    return '-'
                } else return date
            }
            return [
                {
                    title: '證照初登日/標準日',
                    key: 'dueDate',
                    dates: [
                        {
                            label: '壽險',
                            date: getDataState(licenseData?.life?.initRegDate)
                        },
                        {
                            label: '產險',
                            date: getDataState(
                                licenseData?.property?.pptStdDate
                            )
                        },
                        {
                            label: '法遵六小時重疊期間',
                            date: licenseData?.legalCompliance?.startDate
                                ? `${licenseData?.legalCompliance?.startDate}~${licenseData?.legalCompliance?.dueDate}`
                                : '-',
                            lineBreak: !!licenseData?.legalCompliance?.startDate
                        }
                    ],
                    links: [
                        {
                            label: '保發中心\n法遵六小時',
                            link: 'https://score.tii.org.tw/one_easy.php'
                        },
                        {
                            label: '磊山e學院',
                            link:
                                'https://elearning.leishan.app/cltcms/leishan_logout.jsp'
                        }
                    ]
                },
                {
                    title: '證照有效資訊',
                    key: 'effectInfo',
                    dates: [
                        {
                            label: '壽險有效期限',
                            date: getDataState(licenseData?.life?.dueDate)
                        },
                        {
                            label: '產險有效日期',
                            date: getDataState(licenseData?.property?.dueDate)
                        }
                    ],
                    links: [
                        {
                            label: '換證申請書及相關作業規範',
                            link:
                                'https://km.leishan.app/?type=all&keyword=%E8%AD%89%E7%85%A7%20%E6%8F%9B%E8%AD%89&document_type_id&sub_document_type_id&product_type_id&sub_product_type_id&supplier_id&valid_from&valid_to&age_from&age_to&period_from&period_to&order_by=id&page=1'
                        }
                    ]
                }
            ]
        },
        applyLicense: function () {
            return this.$store.state.dashboardLicense?.legalCompliance
                ?.applyLicense
        }
    },
    data() {
        return {
            isLoading: false,
            isError: false
        }
    },
    async mounted() {
        if (
            this.$store.state.dashboardLicense === null ||
            (typeof this.$store.state.dashboardLicense === 'object' &&
                Object.keys(this.$store.state?.dashboardLicense).length ===
                    0) ||
            this.$shouldUpdateHeaderApi()
        )
            await this.getLicenseInfo()
    }
}
</script>

<style lang="scss" scoped>
:deep(.modal-background) {
    .modal-size {
        width: 330px;
        max-width: 330px;
        background: $primary-white;
        max-height: calc(100vh - 100px);
        @media screen and (max-width: 576px) {
            width: 100%;
        }
        @media (min-width: 577px) and (max-width: 1280px) {
            max-height: calc(100vh - 70px);
        }
    }
}

:deep(.modal-title),
:deep(.modal-bottom) {
    background: $primary-white;
    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: $dashboard-gradient-lightest;
        top: 0;
        left: 0;
        z-index: -1;
        transform: translateZ(101px);
    }
}
:deep(.modal-title) {
    padding-bottom: 10px;
    .title {
        margin-bottom: 0px;
    }
}

:deep(.modal-content) {
    background: $dashboard-gradient-lightest;
}

:deep(.modal-bottom) {
    padding: 10px;
}

:deep(.title) {
    > div {
        font-size: 18px;
        @media screen and (max-width: 576px) {
            font-size: 20px;
        }
    }
}

:deep(.modal-content) {
    background: $dashboard-gradient-lightest;
}

.dashboard-container {
    box-shadow: none;
    padding: 10px;
    &:not(:first-of-type) {
        margin-top: 10px;
    }
    p {
        margin: 0 0 8px 0;
    }
    .subtitle {
        color: $secondary-grey;
        font-size: 15px;
    }
    .gradient-dot-icon {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 10px;
    }

    .date-column {
        display: flex;
        align-items: center;
        color: $sixth-black;
        font-size: 14px;

        &.line-break {
            flex-direction: column;
            align-items: start;
        }
        &.preload {
            .text-line {
                display: inline-block;
                width: 72px;
                height: 20px;
            }
        }
        &.last-item {
            margin-bottom: 0;
        }
    }

    .note {
        font-size: 12px;
        color: $placeholder-black;
        margin-top: 8px;
        .red {
            color: $fourth-red;
        }
    }

    .link-container {
        display: flex;
        flex-wrap: wrap;

        .link-button {
            display: block;
            margin-top: 8px;
            white-space: pre-wrap;
            flex: 1 1 auto;
            &:first-child {
                margin-right: 10px;
            }
            &:last-child {
                margin-right: 0px;
            }
            .button {
                width: 100%;

                :deep(.button-word) {
                    font-size: 12px;
                }
            }
        }
    }
}

//錯誤訊息
.error {
    border: 1px solid $primary-red;
    p {
        margin: 0;
        &.subtitle {
            margin-bottom: 5px;
            color: $primary-red;
            font-size: 16px;
            font-weight: bold;
            &.loading {
                color: $secondary-grey;
            }
        }
    }

    .date-column {
        display: block;
        span {
            text-decoration: underline;
            color: $fourth-blue;
            cursor: pointer;
        }
    }
}
</style>
